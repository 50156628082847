import React from "react";
import { useState } from "react";
import icon from "../../asset/image/livechats.gif";

const LiveChat = () => {
  const [open, setIsOpen] = useState(false);
  return (
    <main>
      <div
        onClick={() => setIsOpen(!open)}
        className="livechat football bg-info rounded-circle border-2 border border-info"
      >
        <img
          className="rounded-circle p-1"
          style={{ width: "3.6rem" }}
          src={icon}
          alt="livec"
        />
      </div>
      <div className={`${open ? "d-block" : "d-none"} lciframe`}>
        <iframe
          src="https://marsgaming.org/livechat.html"
          title="Live Chat Widget"
          className="livechatiframe"
        />
      </div>
    </main>
  );
};

export default LiveChat;
