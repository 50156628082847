import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfigs = {
  apiKey: "AIzaSyAJH4_58NnZTtL30MVglJctJOtE-rs63Jg",
  authDomain: "marsgaming-edcfc.firebaseapp.com",
  databaseURL: "https://marsgaming-edcfc-default-rtdb.firebaseio.com",
  projectId: "marsgaming-edcfc",
  storageBucket: "marsgaming-edcfc.appspot.com",
  messagingSenderId: "486032731360",
  appId: "1:486032731360:web:060dbea9ae9234fcfc7485",
  measurementId: "G-TG9491QCD0",
};

const app = initializeApp(firebaseConfigs, "realtimedb");
const db = getDatabase(app);

export { db };
