import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBTkGGvxer4UQcfl83RXvWdoL1tUaoaODY",
  authDomain: "myvipwebsite-e2673.firebaseapp.com",
  databaseURL:
    "https://myvipwebsite-e2673-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "myvipwebsite-e2673",
  storageBucket: "myvipwebsite-e2673.appspot.com",
  messagingSenderId: "428843092516",
  appId: "1:428843092516:web:8d901da7c462375906a6b1",
  measurementId: "G-2HM67SXYMQ",
};

const app = initializeApp(firebaseConfig, "secondary");
let messaging = null;
try {
  messaging = getMessaging(app);
} catch (error) {
  console.log("Does not support current browser or be use https");
}
const database = getDatabase(app);
const vapidKey =
  "BHzjcdGdUHweUm-ZMu0zZcMtxeDNxNTkIgSXPJwCCOjW4Y4uY4ghM-gKfHxUiRsAfuzrsqLTyiUetk8FKKaWT2o";

export { ref, onValue, database, messaging, onMessage, vapidKey, getToken };
