// @ts-nocheck
import React, { lazy, Suspense } from "react";
// lib
import { Routes, Route } from "react-router-dom";
// comp
import Loading from "../components/shared_comp/Loading";
import Protected from "../components/shared_comp/Protected";
import NotFound from "../pages/NotFound";
import WinnerList from "../pages/WinnerList";
import Category from "../pages/Games/Category";
import ProviderGame from "../pages/Games/ProviderGame";
import BetSlipDetail from "../pages/BetSlipDetail";

// pages
const Home = lazy(() => import("../pages/Home"));
const OneLottery = lazy(() => import("../pages/OneLottery"));
const TwoLottery = lazy(() => import("../pages/TwoLottery"));
const ThreeLottery = lazy(() => import("../pages/ThreeLottery"));
const CryptoLottery = lazy(() => import("../pages/CryptoLottery"));
const CryptoOneLottery = lazy(() => import("../pages/CryptoOneLottery"));
const Profile = lazy(() => import("../pages/Profile"));
const History = lazy(() => import("../pages/History"));
const CashIn = lazy(() => import("../pages/CashIn"));
const CashOut = lazy(() => import("../pages/CashOut"));
const Login = lazy(() => import("../pages/Login"));
const Register = lazy(() => import("../pages/Register"));
const ForgotPass = lazy(() => import("../pages/ForgotPass"));
const WinNumber = lazy(() => import("../pages/WinNumber"));
const Point = lazy(() => import("../pages/Point"));

const MainRoute = ({
  socialLink = null,
  provider = [],
  hotLoading = false,
  lottery = [],
}) => {
  return (
    <Suspense fallback={<Loading full={true} loading={true} />}>
      <Routes>
        {/* Auth Routes */}
        <Route
          path="/profile"
          element={
            <Protected>
              <Profile />
            </Protected>
          }
        />
        <Route
          path="/cashin"
          element={
            <Protected>
              <CashIn />
            </Protected>
          }
        />
        <Route
          path="/cashout"
          element={
            <Protected>
              <CashOut />
            </Protected>
          }
        />
        <Route path="/two/lottery" element={<TwoLottery />} />
        <Route path="/three/lottery" element={<ThreeLottery />} />
        <Route path="/crypto/lottery" element={<CryptoLottery />} />
        <Route path="/crypto/one/lottery" element={<CryptoOneLottery />} />
        <Route path="/one/lottery" element={<OneLottery />} />
        <Route
          path="/betslip"
          element={
            <Protected>
              <History />
            </Protected>
          }
        />
        <Route
          path="/game/report/:p_code"
          element={
            <Protected>
              <BetSlipDetail />
            </Protected>
          }
        />
        {/** <Route
          path="/referral"
          element={
            <Protected>
              <Referral />
            </Protected>
          } /> */}

        {/* No Auth Routes */}
        <Route
          path="/"
          element={<Home {...{ socialLink, provider, hotLoading, lottery }} />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/win-number" element={<WinNumber />} />
        <Route path="/winner-list" element={<WinnerList />} />
        <Route path="/:cat" element={<Category />} />
        <Route path="/:cat/:prov" element={<ProviderGame />} />
        <Route path="/forgetpwd" element={<ForgotPass />} />
        <Route path="/point" element={<Point />} />
        {/* All Games */}
        {/* No Match Routes  */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default MainRoute;
