// @ts-nocheck
import React, { useContext, useState } from "react";
import { BaseURL } from "../../api/apiUrl";

import {
  CreditCardIcon,
  BanknotesIcon,
  BuildingStorefrontIcon,
  UserGroupIcon,
  QueueListIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/solid";

// lib
import { Link, useNavigate } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
// images
import logo from "../../asset/image/ICON/favicon.png";
// comp
import HeaderMarquee from "../../components/shared_comp/HeaderMarquee";
// utils
import { CN, CURRENT_LANG, EN, MM, THAI } from "../../localization/langConfig";
import { removeLocalStorageNoJson } from "../../utils/localStorage";
// image
import MMFlag from "../../asset/image/localization/mm.png";
import UKFlag from "../../asset/image/localization/uk.png";
import ChinaFlag from "../../asset/image/localization/china.png";
import ThaiFlag from "../../asset/image/localization/tai.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { LoginContext } from "../../store/context/LoginProvider";
import { moneyFormatter } from "../../utils/moneyFormatter";

const FlagImage = ({ flagImg, width }) => {
  return (
    <img style={{ width, objectFit: "contain" }} src={flagImg} alt="..." />
  );
};

const MobileHeader = ({
  marquee,
  userData,
  isDisabled,
  handleToggle,
  handleLogout,
}) => {
  const [
    {
      gameManagement: { category },
    },
  ] = useContext(LoginContext);

  let { t } = useTranslation();
  const getLang = removeLocalStorageNoJson(CURRENT_LANG);
  const [language, setchangeLanguage] = useState(getLang ? getLang : MM);
  const [openLang, setOpenLang] = useState(false);
  const navigate = useNavigate();

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    setchangeLanguage(lang);
    setOpenLang(false);
  };
  const toggleSideBar = () => {
    document
      .getElementById("offcanvasWithBothOptions")
      .classList.toggle("show");
  };

  return (
    <>
      {/** Navbar */}
      <div className="d-flex px-2 position-relative d-lg-none justify-content-between align-items-center">
        <div className="d-flex gap-2">
          {/** Hamburgar */}
          <svg
            className="bg-white rounded-5"
            onClick={toggleSideBar}
            xmlns="http://www.w3.org/2000/svg"
            height="27px"
            viewBox="0 0 24 24"
            width="27px"
            fill="#E2A101"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
          </svg>
          {/* Lang Switch */}
          <div
            onClick={() => setOpenLang(!openLang)}
            className="d-flex cursor-pointer align-items-center px-2"
          >
            {language === EN ? (
              <FlagImage width={35} flagImg={UKFlag} />
            ) : language === MM ? (
              <FlagImage width={35} flagImg={MMFlag} />
            ) : language === CN ? (
              <FlagImage width={35} flagImg={ChinaFlag} />
            ) : (
              <FlagImage width={35} flagImg={ThaiFlag} />
            )}
            {/* Expand Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#fff"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
            </svg>
          </div>
        </div>
        {/* Logo */}
        <Link to="/">
          <img
            src={logo}
            className="shadow-lg rounded-circle border border-1 border-dark my-1"
            style={{ height: "50px", marginLeft: 0 }}
            alt="..."
          />
        </Link>

        {userData?.token && (
          <div
            className="d-flex ps-3 justify-content-between align-items-center gap-3"
            style={{ color: "goldenrod" }}
          >
            <div className="bg-info bg-opacity-75 rounded-2 p-1 text-center d-flex flex-wrap justify-content-center align-items-center">
              {/* Main Balance */}
              <CurrencyDollarIcon
                style={{
                  width: "1.5rem",
                  color: "white",
                }}
              />
              <span className="px-1 fw-bolder fs-6 font-monospace px-md-3 text-white">
                {userData?.balance
                  ? moneyFormatter(parseInt(userData?.balance || 0))
                  : 0}
              </span>
            </div>
          </div>
        )}

        {/* Land Switch: DropDown  */}
        <div
          className={`position-absolute ${
            openLang ? "d-flex" : "d-none"
          } flex-column gap-1 bg-white rounded-3 p-2`}
          style={{ left: 30, top: 52, zIndex: 9999 }}
        >
          <div
            className={`${
              language !== MM ? "d-flex" : "d-none"
            } gap-2 align-items-center cursor-pointer`}
            onClick={() => changeLang(MM)}
          >
            <FlagImage width={25} flagImg={MMFlag} />
            <span>ဗမာစာ</span>
          </div>
          <div
            className={`${
              language !== EN ? "d-flex" : "d-none"
            } gap-2 align-items-center cursor-pointer`}
            onClick={() => changeLang(EN)}
          >
            <FlagImage width={25} flagImg={UKFlag} />
            <span>English</span>
          </div>
          <div
            className={`${
              language !== THAI ? "d-flex" : "d-none"
            } gap-2 align-items-center cursor-pointer`}
            onClick={() => changeLang(THAI)}
          >
            <FlagImage width={25} flagImg={ThaiFlag} />
            <span>ไทย</span>
          </div>
          <div
            className={`${
              language !== CN ? "d-flex" : "d-none"
            } gap-2 align-items-center cursor-pointer`}
            onClick={() => changeLang(CN)}
          >
            <FlagImage width={25} flagImg={ChinaFlag} />
            <span>中国人</span>
          </div>
        </div>
      </div>

      {/** Marquee */}
      <div className="d-flex d-lg-none mt-1 mb-0 mb-md-2 align-items-center">
        <HeaderMarquee marquee={marquee} />
      </div>

      {/* Mobile SideBar */}
      <div
        data-bs-backdrop="false"
        className="offcanvas bg-black offcanvas-start border-end border-danger text-white d-lg-none overflow-auto pb-5"
        data-bs-scroll="true"
        tabIndex={-1}
        id="offcanvasWithBothOptions"
        aria-labelledby="offcanvasWithBothOptionsLabel"
        style={{ width: "100%" }}
      >
        <div className="offcanvas-header">
          <h4
            className="offcanvas-title fw-bolder text-warning font-monospace"
            id="offcanvasWithBothOptionsLabel"
          >
            MARS GAMING
          </h4>
          <button
            type="button"
            className="btn-close btn-close-white"
            onClick={toggleSideBar}
          ></button>
        </div>

        {/** side bar toggle */}
        <div className="w-100 h-100 mb-4">
          {/** Profile Div */}
          <div
            className="text-center mx-auto rounded-2 "
            style={{
              width: "90%",
              backgroundColor: "#212121",
              marginTop: "1rem",
            }}
          >
            <div className="d-block">
              <div>
                <button
                  onClick={() => {
                    navigate("/profile");
                    toggleSideBar();
                  }}
                  className="btn btn-warning col-12 rounded-pill"
                >
                  <b>{userData?.token ? `${t("profile")}` : `${t("login")}`}</b>
                </button>
              </div>
            </div>
          </div>

          {/** History */}
          {userData && (
            <div
              onClick={() => {
                navigate("/betslip");
                toggleSideBar();
              }}
              style={{ width: "90%" }}
              className="mx-auto bg-success bg-opacity-25 d-flex align-items-center gap-3 rounded-3 mt-3 py-2 cursor-pointer"
            >
              <BuildingStorefrontIcon
                className="text-success ms-4"
                width={25}
              />
              <div className=" text-md text-white text-opacity-50">
                {t("history")}
              </div>
            </div>
          )}

          {/** WinnerHistory */}
          {userData && (
            <div
              style={{ width: "90%", backgroundColor: "#212121" }}
              className="mx-auto bg-opacity-25 rounded-3 mt-3 py-2"
            >
              <div
                onClick={() => {
                  navigate("/winner-list");
                  toggleSideBar();
                }}
                className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
              >
                <UserGroupIcon className="text-warning ms-4" width={25} />
                <div className=" text-md text-white text-opacity-50">
                  {t("winnerListHistory.title")}
                </div>
              </div>

              <div
                onClick={() => {
                  navigate("/win-number");
                  toggleSideBar();
                }}
                className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
              >
                <QueueListIcon className="text-warning ms-4" width={25} />
                <div className=" text-md text-white text-opacity-50">
                  {t("winNoHistory")}
                </div>
              </div>
            </div>
          )}

          {/** Transfer */}
          {userData && (
            <div
              style={{ width: "90%", backgroundColor: "#212121" }}
              className="mx-auto bg-opacity-25 rounded-3 mt-3 py-2"
            >
              <div
                onClick={() => {
                  navigate("/cashin");
                  toggleSideBar();
                }}
                className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
              >
                <CreditCardIcon className="text-warning ms-4" width={25} />
                <div className=" text-md text-white text-opacity-50">
                  {t("cashIn")}
                </div>
              </div>

              <div
                onClick={() => {
                  navigate("/cashout");
                  toggleSideBar();
                }}
                className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
              >
                <BanknotesIcon className="text-warning ms-4" width={25} />
                <div className=" text-md text-white text-opacity-50">
                  {t("cashOut")}
                </div>
              </div>
            </div>
          )}

          {/** Category */}
          <div
            style={{ width: "90%", backgroundColor: "#212121" }}
            className="mx-auto bg-opacity-25 rounded-3 mt-3 py-2"
          >
            <div
              onClick={() => {
                navigate("/");
                toggleSideBar();
              }}
              className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
            >
              <img
                alt="..."
                className="ms-4"
                style={{ width: "1.3rem" }}
                src="https://cdn-icons-png.flaticon.com/512/1299/1299961.png"
              />
              <div className=" text-md text-white text-opacity-50">
                {t("home")}
              </div>
            </div>

            {category?.map((cat, idx) => (
              <div
                key={idx}
                onClick={() => {
                  navigate(`${cat.name}`);
                  toggleSideBar();
                }}
                className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
              >
                <LazyLoadImage
                  effect="blur"
                  className="ms-4"
                  style={{ width: "1.5rem" }}
                  src={`${BaseURL}/${cat.image}`}
                />
                <div className="text-md text-white font-monospace text-opacity-50 fw-bolder">
                  {cat.name.toUpperCase()}
                </div>
              </div>
            ))}
          </div>

          {/** Logout */}
          {userData && (
            <div
              onClick={() => {
                handleLogout();
                toggleSideBar();
              }}
              style={{ width: "90%" }}
              className="mx-auto bg-danger bg-opacity-25 d-flex align-items-center gap-3 rounded-3 mt-3 py-2 cursor-pointer"
            >
              <BuildingStorefrontIcon className="text-danger ms-4" width={25} />
              <div className=" text-md text-white text-opacity-50">
                {t("logout")}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MobileHeader;
